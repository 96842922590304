import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Brightness1 as TrackDot,
  Edit,
  LocalLibrary as View,
  Autorenew as Renew,
  Event
} from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import {
  CircularProgress,
  Box,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableContainer,
  TableRow
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RenewGoal from './RenewGoal';
import useStyles from '../../styles/goals/goalFormStyles';

import {
  GOAL_STATUS,
  GOAL_TRACK,
  GOAL_DASH_VIEWS,
  MODAL
} from '../../helpers/constants';
import AutoScroll from '../common/AutoScroll';
import { useGoalState, useGoalDispatch } from '../../context/GoalContext';
import { useAuth } from '../../context/AuthContext';
import {
  regularSort,
  convertToUpperCase,
  idMatch
} from '../../helpers/helpers';
import {
  getMuiTheme,
  getEmptyMuiTheme,
  goalFlagHighlight,
  hideGoalDropdownIcon
} from '../../styles/common/reviewTableStyles';
import globalTheme from '../../styles/globalTheme';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_CALENDAR_EVENT_FOR_GOAL } from '../../graphql/goalMutations';
import useToast from '../../hooks/useToast';
import { CalendarEventModal } from './GoalModals';

const GoalsReviewTable = ({
  data: goalData,
  selectable,
  selectGoals,
  selectRows,
  selectedRows
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [selectedRowData, setSelectedRowData] = useState();
  const [openRenewModal, setOpenRenewModal] = useState(null);
  const {
    state: { view }
  } = useGoalState();
  const { goalDispatch } = useGoalDispatch();
  const history = useHistory();
  const classes = useStyles();
  const normalizeDate = date => (date ? date.slice(0, 10) : '');
  const {
    refreshLogin,
    userData: { email, employeeId }
  } = useAuth();
  const { displayToast } = useToast();

  const [createCalendarEventForGoal] = useMutation(
    CREATE_CALENDAR_EVENT_FOR_GOAL
  );

  useEffect(() => {
    goalDispatch({
      type: 'resetGoalFormConfig',
      payload: {}
    });
  }, [goalDispatch]);

  // Conditionals
  const viewIsMyGoals = view === GOAL_DASH_VIEWS.MY_GOALS;
  const calendarEventModalIsOpen = () => openModal === MODAL.CALENDAR;

  const getTrackColor = rowData => {
    const [, , , , track] = rowData;
    switch (track) {
      case GOAL_TRACK.ON_TRACK.NAME:
        return { color: globalTheme.palette.active.main };
      case GOAL_TRACK.AT_RISK.NAME:
        return { color: globalTheme.palette.secondary.main };
      case GOAL_TRACK.OFF_TRACK.NAME:
        return { color: globalTheme.palette.primary.main };
      case GOAL_TRACK.NO_FLAG_SET.NAME:
        return { color: globalTheme.palette.neutral.light };
      default:
        throw new Error('Not a valid goal track.');
    }
  };

  const goalIsFinished = status => {
    return (
      status === GOAL_STATUS.COMPLETED.NAME ||
      status === GOAL_STATUS.CANCELLED.NAME
    );
  };

  const canCreateCalendarEventForGoal = rowData => {
    // There is kind of a chicken and egg thing with functions used in the column definition
    // which reference row data by column index.
    const dueDate = rowData[3];
    return !!dueDate;
  };

  const canViewCompletedGoal = rowData => {
    const [status] = rowData;
    return goalIsFinished(status);
  };

  const canEditOpenGoal = rowData => {
    const [status] = rowData;
    return !goalIsFinished(status);
  };

  // shorten goal title for table view
  const truncateTitle = rowData => {
    const [, , title] = rowData;

    const MAX_CHARS = 20;

    if (title.length <= MAX_CHARS) return title;

    return `${title.substring(0, MAX_CHARS)}...`;
  };

  const openRenewGoalModal = () => {
    setOpenRenewModal(MODAL.GOAL);
  };

  const closeRenewModal = () => {
    setOpenRenewModal(null);
  };

  // set data for the opened evaluation into state & navigate to the Goal
  const openGoal = existingGoalData => {
    const [
      goalStatus,
      createdFor,
      title,
      dueDate,
      goalTrack,
      shareToName,
      shareToEmail,
      description,
      startDate,
      completedDate,
      goalCategory,
      goalId,
      createdForEmail,
      createdForEmployeeId,
      createdForFlag,
      shareToFlag,
      createdForJobNumber,
      shareToEmployeeId
    ] = existingGoalData;

    goalDispatch({
      type: 'setGoalFormConfig',
      payload: {
        status: goalStatus,
        goalForName: createdFor,
        goalTitle: title,
        goalForEmail: createdForEmail,
        goalForJobNumber: createdForJobNumber,
        goalForEmployeeId: createdForEmployeeId,
        dueDate,
        track: goalTrack,
        shareToName,
        shareToEmail,
        shareToEmployeeId,
        goalDescription: description,
        startDate,
        completedDate,
        category: goalCategory,
        goalId,
        createdForFlag,
        shareToFlag
      }
    });

    history.push('/goal');
  };

  // Table structure
  const columns = [
    {
      name: 'goalStatus',
      label: 'Status'
    },
    {
      name: 'createdFor',
      label: 'Goal For'
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          return truncateTitle(rowData);
        }
      }
    },
    {
      name: 'dueDate',
      label: 'Due Date'
    },
    {
      name: 'goalTrack',
      label: 'Track',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          return (
            <section>
              <TrackDot style={getTrackColor(rowData)} data-cy="publishedBtn" />
            </section>
          );
        }
      }
    },
    {
      name: 'shareToName',
      options: { display: false }
    },
    {
      name: 'shareToEmail',
      options: { display: false }
    },
    {
      name: 'description',
      options: { display: false }
    },
    {
      name: 'startDate',
      options: { display: false }
    },
    {
      name: 'completedDate',
      options: { display: false }
    },
    {
      name: 'goalCategory',
      options: { display: false }
    },
    {
      name: 'goalId',
      options: { sortDirection: 'desc', display: false }
    },
    {
      name: 'createdForEmail',
      options: { display: false }
    },
    {
      name: 'createdForEmployeeId',
      options: { display: false }
    },
    {
      name: 'createdForFlag',
      options: { display: false }
    },
    {
      name: 'shareToFlag',
      options: { display: false }
    },
    {
      name: 'createdForJobNumber',
      options: { display: false }
    },
    {
      name: 'shareToEmployeeId',
      options: { display: false }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const { rowData } = tableMeta;
          return (
            <section>
              {canCreateCalendarEventForGoal(rowData) && (
                <Event
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenModal(MODAL.CALENDAR);
                    setSelectedRowData(rowData);
                  }}
                  data-cy="calendarEventBtn"
                />
              )}
              {canViewCompletedGoal(rowData) && (
                <View
                  style={{ cursor: 'pointer' }}
                  onClick={() => openGoal(rowData)}
                  data-cy="viewBtn"
                />
              )}
              {canEditOpenGoal(rowData) && (
                <Fragment>
                  <Edit
                    style={{ cursor: 'pointer' }}
                    onClick={() => openGoal(rowData)}
                    data-cy="editBtn"
                  />
                  <Renew
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedRowData(rowData);
                      openRenewGoalModal();
                    }}
                    data-cy="renewBtn"
                  />
                  {openRenewModal === MODAL.GOAL && (
                    <RenewGoal
                      isOpen={openRenewModal === MODAL.GOAL}
                      closeModal={closeRenewModal}
                      renderType="modal"
                      goalInfo={selectedRowData}
                    />
                  )}
                </Fragment>
              )}
            </section>
          );
        }
      }
    }
  ];

  const closeModal = () => {
    setOpenModal(null);
  };

  const getColumnIndex = columnName =>
    columns.findIndex(column => column.name === columnName);

  const createCalendarEvent = () => {
    closeModal();
    setIsLoading(true);
    const variables = {
      goalId: selectedRowData[getColumnIndex('goalId')],
      token: localStorage.getItem('accessToken')
    };
    createCalendarEventForGoal({
      variables
    })
      .catch(async e => {
        const token = await refreshLogin();
        return createCalendarEventForGoal({
          variables: {
            ...variables,
            token
          }
        });
      })
      .catch(e => {
        displayToast(
          'Error: Something went wrong while trying to create a calendar event. Please try again. If the problem persists, please contact support.',
          'error'
        );
        setIsLoading(false);
        throw e;
      })
      .then(r => {
        displayToast(
          'An event has been successfully added to your calendar.',
          'success'
        );
        setIsLoading(false);
      });
  };

  /* Custom Sort Function */
  const sortColumns = (data, colIndex, order) => {
    // the undisplayed goalId column allows for a default sort
    // separate from the user-sortable columns
    const idIndex = getColumnIndex('goalId');

    return data.sort((a, b) => {
      if (colIndex === idIndex) {
        return defaultSort(a.data, b.data, order);
      }

      // regular sorting for other columns
      const itemA = convertToUpperCase(a.data[colIndex]);
      const itemB = convertToUpperCase(b.data[colIndex]);

      return regularSort(itemA, itemB, order);
    });
  };

  const defaultSort = (dataA, dataB, order) => {
    const statusOrder = [
      GOAL_STATUS.IN_PROGRESS.NAME,
      GOAL_STATUS.NOT_STARTED.NAME,
      GOAL_STATUS.ON_HOLD.NAME,
      GOAL_STATUS.COMPLETED.NAME,
      GOAL_STATUS.CANCELLED.NAME
    ];

    const statusColumn = getColumnIndex('goalStatus');
    const dueDateColumn = getColumnIndex('dueDate');

    // sort by status
    const statusA = dataA[statusColumn];
    const statusB = dataB[statusColumn];

    const statusAIndex = statusOrder.indexOf(statusA);
    const statusBIndex = statusOrder.indexOf(statusB);

    if (statusAIndex < statusBIndex) return -1;
    if (statusBIndex < statusAIndex) return 1;

    // if statuses are equal, sort by due date
    const dueDateA = dataA[dueDateColumn];
    const dueDateB = dataB[dueDateColumn];

    return regularSort(dueDateA, dueDateB, order);
  };

  const getSelectableRows = () => {
    if (viewIsMyGoals && selectable) return 'multiple';
    return 'none';
  };

  const getSelectedGoals = (currentRowsSelected, allRowsSelected) => {
    const newSelectedRows = allRowsSelected.map(row => row.dataIndex);
    const selectedGoals = newSelectedRows.map(index => goalData[index].goalId);

    selectRows(newSelectedRows);
    selectGoals(selectedGoals);
  };

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20],
    selectableRows: getSelectableRows(),
    rowsSelected: selectedRows,
    expandableRows: true,
    expandableRowsOnClick: true,
    customToolbarSelect: () => {},
    onRowsSelect: getSelectedGoals,
    customSort: sortColumns,
    setRowProps: row => {
      const shareToEmail = row[6];
      const goalDescription = row[7];
      const goalStartDate = row[8];
      const goalCompletedDate = row[9];
      const goalCategory = row[10];
      const createdForEmail = row[12];
      const createdForEmployeeId = row[13];
      const createdForFlag = row[14];
      const shareToFlag = row[15];
      const shareToEmployeeId = row[17];

      const sharingTo =
        (idMatch(shareToEmail, email) ||
          idMatch(shareToEmployeeId, employeeId)) &&
        shareToFlag;
      const createdFor =
        (idMatch(createdForEmail, email) ||
          idMatch(createdForEmployeeId, employeeId)) &&
        createdForFlag;

      if (sharingTo || createdFor) return goalFlagHighlight();
      if (
        !goalDescription &&
        !goalStartDate &&
        !goalCompletedDate &&
        goalCategory === 'Select a Category'
      ) {
        return hideGoalDropdownIcon();
      }
      return null;
    },
    isRowExpandable: dataIndex => {
      const goalRowData = goalData[dataIndex];
      const goalDescription = goalRowData.description;
      const goalStartDate = goalRowData.startDate;
      const goalCompletedDate = goalRowData.completedDate;
      const goalCategory = goalRowData.goalCategory;
      return (
        goalDescription ||
        goalStartDate ||
        goalCompletedDate ||
        goalCategory !== 'Select a Category'
      );
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const goalRowData = goalData[rowMeta.dataIndex];
      const goalCategory =
        goalRowData.goalCategory === 'Select a Category'
          ? ''
          : goalRowData.goalCategory;
      const dropdownSectionTitles = [
        'Description',
        'Start Date',
        'Completed Date',
        'Goal Category'
      ];
      return (
        <>
          <TableRow>
            <TableCell colSpan={colSpan}>
              <TableContainer>
                <Table>
                  {goalRowData && (
                    <TableHead>
                      <TableRow>
                        {dropdownSectionTitles.map(sectionTitle => (
                          <TableCell
                            key={sectionTitle}
                            className={classes.dropdownSectionTitle}
                          >
                            {sectionTitle}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    <TableRow key={goalRowData.goalId}>
                      <TableCell
                        className={classes.dropdownDescriptionSectionWidth}
                      >
                        {goalRowData.description}
                      </TableCell>
                      <TableCell>
                        {normalizeDate(goalRowData.startDate)}
                      </TableCell>
                      <TableCell>
                        {normalizeDate(goalRowData.completedDate)}
                      </TableCell>
                      <TableCell>{goalCategory}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  return (
    <MuiThemeProvider
      theme={goalData.length ? getMuiTheme() : getEmptyMuiTheme()}
    >
      {isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Fragment>
          <AutoScroll type="scrollToTop" />
          <MUIDataTable data={goalData} columns={columns} options={options} />
          <AutoScroll type="scrollToBottom" />
        </Fragment>
      )}
      {calendarEventModalIsOpen() && (
        <CalendarEventModal close={closeModal} action={createCalendarEvent} />
      )}
    </MuiThemeProvider>
  );
};

GoalsReviewTable.defaultProps = {
  selectable: false
};

GoalsReviewTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      goalId: PropTypes.number,
      goalStatus: PropTypes.string,
      goalForName: PropTypes.string,
      goalForEmail: PropTypes.string,
      goalTitle: PropTypes.string,
      dueDate: PropTypes.string,
      goalTrack: PropTypes.string
    })
  ).isRequired,
  selectable: PropTypes.bool,
  selectGoals: PropTypes.func.isRequired,
  selectRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default GoalsReviewTable;
